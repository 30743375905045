import * as React from "react"
import AppBar from "@material-ui/core/AppBar"
import Tabs, {TabsClassKey} from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {Theme, WithStyles, withStyles, createStyles, Zoom, WithTheme} from "@material-ui/core"
import * as path from "path"
import {RouteComponentProps, withRouter} from "react-router"

interface Props extends WithStyles<typeof styles>, WithTheme, RouteComponentProps<{}> {
    items: TabDisplayItem[]
    defaultTab?: number
    parentPath: string
    tabsClasses?: Partial<Record<TabsClassKey, string>>
}

export interface TabDisplayItem {
    label: React.ReactNode
    link?: string
    fab?: React.ReactNode
    content: React.ReactNode
    showForSubUrl?: boolean
    onClick?: () => void
}

interface State {
    selected: number | null
}

class TabDisplayComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            selected: this.getSelectedTabFromUrl()
        }
    }

    public render() {
        const {classes, theme, items, children} = this.props
        const {selected} = this.state
        const transitionDuration = {
            enter: theme!.transitions.duration.enteringScreen,
            exit: theme!.transitions.duration.leavingScreen
        }

        return (
            <div className={classes.root}>
                {this.getTabBar()}
                {this.displayContent()}
                {items.map((item, index) => (
                    item.fab && <Zoom
                        key={item.link}
                        in={selected === index}
                        timeout={transitionDuration}
                        style={{
                            transitionDelay: `${selected === index ? transitionDuration.exit : 0}ms`
                        }}
                        unmountOnExit
                    >
                        <div className={classes.fab}>
                            {item.fab}
                        </div>
                    </Zoom>
                ))}
                {children}
            </div>
        )
    }

    public componentDidUpdate() {
        const selected = this.getSelectedTabFromUrl()
        if (this.state.selected !== selected) {
            this.setState({selected})
        }
    }

    private readonly renderTab = (item: TabDisplayItem, index: number) => {
        const {history, defaultTab, parentPath} = this.props
        const onClick = item.onClick || (() => history.push(path.join(parentPath, defaultTab === index ? "/" : item.link!)))
        return <Tab key={item.link} label={item.label} onClick={onClick}/>
    }

    private readonly handleChange = (event: React.ChangeEvent<{}>, selected: number) => {
        this.setState({selected})
    }

    private readonly getSelectedTabFromUrl = () => {
        const {items, location, defaultTab, parentPath} = this.props
        if (defaultTab !== undefined && location.pathname === parentPath) {
            return defaultTab
        }
        for (const item of items) {
            if (item.link) {
                const itemUrl = path.join(parentPath, item.link)
                if (location.pathname.indexOf(itemUrl) === 0) {
                    return items.indexOf(item)
                }
            }
        }
        return null
    }

    private readonly getTabBar = () => {
        const {selected} = this.state
        const {items, tabsClasses, classes} = this.props
        return (
            <AppBar position="static" color="default" className={classes.tab}>
                <Tabs
                    value={selected !== null ? selected : false}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    classes={tabsClasses}
                >
                    {items.map(this.renderTab)}
                </Tabs>
            </AppBar>
        )
    }

    private readonly displayContent = () => {
        const {selected} = this.state
        const {items, location, parentPath} = this.props
        if (selected !== null) {
            const item = items[selected]
            if (item.link) {
                const splitPath = (path: string) => path.split("/").filter((e) => e !== "")
                const locationPath = splitPath(location.pathname)
                const itemUrl = splitPath(path.join(parentPath, item.link))
                while (locationPath.length && itemUrl.length && locationPath[0] === itemUrl[0]) {
                    locationPath.shift()
                    itemUrl.shift()
                }
                return locationPath.length && !item.showForSubUrl ? <></> : item.content
            }
            return item.content
        } else {
            return <></>
        }
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: theme.palette.secondary.light
    },
    jerkyFix: {
        height: 1,
        marginBottom: -1
    },
    fab: {
        position: "fixed",
        bottom: 30,
        right: 30
    },
    tab: {
        zIndex: 1000
    }
})

export const TabDisplay = withRouter(withStyles(styles, {withTheme: true})(TabDisplayComponent))
