import {User} from "./models/User";
import {Model, Notice, noticeTypeEnum} from "./models/utils/types";
import {RequestError} from "./requests/utils/requests";
import {configureStore} from "@reduxjs/toolkit";
import {Actions, setupRedux, State} from "../redux/store";
import {Post} from "./models/Post";
import {Member} from "./models/Member";
import {deleteToken} from "./requests/utils/credentials";
import {getLang, Lang} from "../components/lang/Language";
import {Group} from "./models/Group";

export interface StoreObject {
    lang: Lang
    user: User | undefined
    notice: Notice | undefined
    postModal: Post | boolean
    authModalOpen: boolean
}

export const storeObjInit: StoreObject = {
    lang: getLang(),
    user: undefined,
    notice: undefined,
    postModal: false,
    authModalOpen: false
}

export interface StoreArray {
    posts: Post[]
    members: Member[]
    groups: Group[]
}

export const storeArrayInit: StoreArray = {
    posts: [],
    members: [],
    groups: []
}

const configErrorHandler = (actions: Actions<StoreObject, StoreArray>) => (error: RequestError) => {
    if (error.status === 401) {
        deleteToken()
        return actions.user.init()
    } else {
        return actions.notice.loaded({type: noticeTypeEnum.ERROR, message: error.message})
    }
}

const getId = (e: Model) => e.id
export const {reducer, actions, useInvoke, mapDispatch} = setupRedux(storeObjInit, storeArrayInit, getId, configErrorHandler)
export const store = configureStore({reducer})
export type RootState = State<StoreObject, StoreArray>
