import * as React from "react"
import {makeStyles} from "@material-ui/core/styles";
import {User} from "../../app/models/User";
import {Member} from "../../app/models/Member";
import {ImageFileUpload} from "../forms/ImageFileUpload";
import {UserAvatar} from "./UserAvatar";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../../app/store";
import {FileModel} from "../../app/models/File";
import addImage from "../../assets/img/icons/addImageRoundInverted.png"
import pattern from "../../assets/img/background_pattern_opaque.svg";
import {RegaloSet} from "../regalo/RegaloSet";
import {updateUser} from "../../app/requests/auth";
import {useState} from "react";
import EditIcon from "@material-ui/icons/Edit"
import {Button, TextField} from "@material-ui/core";
import {ModalDialog} from "../tools/ModalDialog";

interface Props {
    member: User | Member
}

export function ProfileBanner(props: Props) {
    const classes = useStyles()
    const {member} = props
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    const invoke = useInvoke()
    const handleProfileImageChange = (thumbnail: FileModel) => {
        invoke.user.load(updateUser({thumbnail: thumbnail.image}))
    }
    const avatar =
        user && member.id === user.id ?
            <div className={classes.avatar}>
                <ImageFileUpload
                    thumbnail={user.thumbnail}
                    bgImage={addImage}
                    handleChange={handleProfileImageChange}
                >
                    <UserAvatar classes={{root: classes.avatar}} user={member}/>
                </ImageFileUpload>
            </div>
            :
            <UserAvatar classes={{root: classes.avatar}} user={member}/>

    const [bio, setBio] = useState(member.bio)
    const setBioChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        setBio(event.target.value)
    }
    const handleBioChange = () => {
        invoke.user.load(updateUser({bio}))
    }
    const [editBio, setEditBio] = useState(false)
    const openModal = () => setEditBio(true)
    const closeModal = () => setEditBio(false)
    return (
        <header className={classes.root}>
            <ModalDialog open={editBio}
                         handleClose={closeModal}
                         message={
                             <TextField
                                 name="bio" required fullWidth inputProps={{style: {width: "100%"}}}
                                 label="Bio" multiline rows="4" value={bio} onChange={setBioChange}/>
                         }
                         actions={["Save"]} handleActions={[handleBioChange]}/>
            <div className={classes.container}>
                {avatar}
                <div className={classes.info}>
                    <div className={classes.bio}>{
                        user && user.username === member.username ?
                            <>
                                {member.bio ? member.bio : <span style={{color: "gray"}}>Write your bio</span>}
                                <Button color="primary" onClick={openModal}><EditIcon/> Edit</Button>
                            </> :
                            member.bio}</div>
                    <span>{member.username}</span>
                    <div className={classes.regaloBox}>
                        {(!user || member.id !== user.id) &&
                        <RegaloSet user={member.userToMemberRegalo}
                                   member={member.memberToUserRegalo}/>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        height: 240,
        width: "100%",
        padding: "10px 20px",
        color: theme.palette.primary.dark,
        backgroundColor: "#fff9ff",
        backgroundImage: `url(${pattern})`,
        backgroundSize: "400px 400px",
    },
    container: {
        width: 500,
        display: "flex",
        alignItems: "flex-end",
        zIndex: 1100,
        marginBottom: -30,
        [theme.breakpoints.down("sm")]: {
            width: 400
        },
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 40px)",
            left: 20
        }
    },
    avatar: {
        width: 135,
        height: 135,
        fontSize: 80,
        boxShadow: "0 6px 15px #777",
        borderRadius: "50%",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
            width: 100,
            height: 100,
            fontSize: 60,
            marginTop: 150
        }
    },
    info: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        textAlign: "left",
        justifyContent: "end",
        marginLeft: 20,
        fontSize: 24,
        fontWeight: "bold"
    },
    bio: {
        fontWeight: "normal",
        fontSize: 16,
        marginBottom: 24
    },
    regaloBox: {
        display: "flex",
        justifyContent: "left",
        marginTop: 12,
        marginBottom: 30,
        height: 40
    }
}))
