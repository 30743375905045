import * as React from "react"
import {CircularProgress} from "@material-ui/core"
import {StoreItemState} from "../../redux/types";
import {EmptyContent} from "./EmptyContent";

interface Props<T> extends React.ComponentProps<any> {
    state: StoreItemState<T>
    emptySuccessMessage: React.ReactNode
    failureMessage: React.ReactNode
}

export function StateLoader<T>(props: Props<T>) {

    const {state, children, emptySuccessMessage, failureMessage} = props
    const wrapper = (element: React.ReactNode) => (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
        }}>
            {element}
        </div>)
    if (state.data && (!Array.isArray(state.data) || state.data.length)) {
        return children ? children : <></>
    }
    switch (state.status) {
        case "SUCCESS":
            return emptySuccessMessage ? wrapper(<EmptyContent>{emptySuccessMessage}</EmptyContent>) : <></>
        case "FAILURE":
            return wrapper(<EmptyContent>{failureMessage}</EmptyContent>)
        case "REQUESTED":
            return wrapper(<CircularProgress/>)
        default:
            return children ? children : <></>
    }
}
