import * as React from "react"
import {Link} from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles";

export function Footer() {
    const classes = useStyles()
    return (
        <div className={classes.footer}>
            {/*<div>*/}
            {/*    <Link to="/policy" className={classes.anchor}>Privacy Policy</Link>*/}
            {/*    <Link to="/terms" className={classes.anchor}>Terms and conditions</Link>*/}
            {/*</div>*/}
            <div className={classes.copyright}>
                Copyright © 2020 Amithyst. All rights reserved.
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    footer: {
        width: "100%",
        backgroundColor: "#555",
        marginTop: "auto",
        padding: 20,
        color: "#ddd"
    },
    anchor: {
        margin: 16,
        color: "#ccc",
        "&:hover": {
            color: "#eee"
        }
    },
    copyright: {
        fontSize: 14,
        margin: 20
    }
}))