import {CaseReducer, Draft} from "@reduxjs/toolkit";

type StoreStatus = "INIT" | "REQUESTED" | "SUCCESS" | "FAILURE"
type StoreStatusEnum = {
    [K in StoreStatus]: StoreStatus
}

export const storeStatus: StoreStatusEnum = {
    INIT: "INIT",
    REQUESTED: "REQUESTED",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE"
}

export interface StoreItemState<T> {
    data: T
    status: StoreStatus
    message: string | null
}

export interface DraftStoreItemState<T> {
    data: Draft<T>
    status: StoreStatus
    message: string | null
}

export type StoreState<S> = {
    [K in keyof S]: StoreItemState<S[K]>
}

export type StoreReducers<S> = {
    [K in keyof S]: CaseReducer<StoreItemState<S[K]>>
}