import React, {useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {contentTypeEnum, Post} from "../../app/models/Post";
import {Button, Typography} from "@material-ui/core";
import {PostHeader} from "./PostHeader";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../../app/store";
import {purchaseProduct} from "../../app/requests/posts";
import cover from "../../assets/img/cover_white.svg";
import classNames from "classnames";
import {RegaloSet} from "../regalo/RegaloSet";
import {Regalo} from "../regalo/Regalo";
import {ModalDialog} from "../tools/ModalDialog";
import {useHistory} from "react-router";
import {noticeTypeEnum} from "../../app/models/utils/types";
import ReactAudioPlayer from "react-audio-player";
import DialogContentText from "@material-ui/core/DialogContentText";
import {getToken} from "../../app/requests/utils/credentials";

interface Props {
    post: Post
}

export function PostDetails(props: Props) {
    const classes = useStyles();
    const {post} = props
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    const invoke = useInvoke()
    const handleUnwrap = () => invoke.posts.addOne(purchaseProduct(post.id))
        .then(() => invoke.notice._load({
            type: noticeTypeEnum.SUCCESS,
            message: "This was successfully added to your collections"
        }))
    const token = getToken()
    const postFile = `${post.file}?user=${user && user.username}&token=${token && token.substring(0, 10)}`
    const handleUnwrapAnonymousUser = () =>
        invoke.posts.addOne(Promise.resolve({...post, hasPurchased: true}))
    const [dialogOpen, setDialogOpen] = useState(false)
    const [alertOpen, setModalOpen] = useState(false)
    const openDialog = () => setDialogOpen(true)
    const closeDialog = () => setDialogOpen(false)
    const openAlert = () => setModalOpen(true)
    const closeAlert = () => setModalOpen(false)
    const history = useHistory()
    const openLoginModal = () => history.push("?login")
    const disabled = post.creator.userToMemberRegalo < post.value
    const blur = !post.hasPurchased && !post.ownPost
    const getOverlay = () => {
        if (blur) {
            return (
                <div className={classNames(classes.mediaOverlay, classes.mediaOverlayCover)}>
                    <Button variant="contained" color='secondary'
                            className={classes.button}
                            style={{marginTop: '30%'}}
                            classes={disabled ? {
                                root: classes.disabled
                            } : {}}
                            onClick={disabled ? openAlert :
                                (user ? handleUnwrap : openDialog)}
                    >
                        Unwrap with <Regalo variant="send" value={post.value}/> Regalo
                    </Button>
                </div>
            )
        } else if (post.contentType === contentTypeEnum.AUDIO) {
            return <div className={classes.mediaOverlay}>
                <ReactAudioPlayer
                    src={postFile}
                    controls
                    className={classes.audioPlayer}
                />
            </div>
        } else if (post.contentType === contentTypeEnum.PDF) {
            return <div className={classes.mediaOverlay}>
                <a href={postFile} target="_blank" rel="noopener noreferrer">
                    <Button color="secondary" variant="contained" className={classes.button}>View PDF</Button>
                </a>
            </div>
        } else {
            return <></>
        }
    }
    const overlay = getOverlay()
    return (
        <>
            <ModalDialog open={alertOpen} handleClose={closeAlert}
                         message={
                             <DialogContentText>
                                 {user ? "You don't have enough Regalo to view this post. " +
                                     "Share something yourself to collect more Regalo" :
                                     "You have to be logged in to view this post"}
                             </DialogContentText>}
                         actions={user ? [] : ["Login"]}
                         handleActions={user ? [] : [openLoginModal]}/>
            <ModalDialog open={dialogOpen} handleClose={closeDialog}
                         title="Show the creator your appreciation"
                         message={
                             <DialogContentText>
                                 You can view this work without logging in.
                                 If you do log in, however, the creator will receive an extra Regalo as a token of
                                 appreciation.
                                 You will be able to find this post again easily from the "my collections" tab.
                                 Would you like to log in?
                             </DialogContentText>}
                         actions={["Proceed without logging in", "Login"]}
                         handleActions={[handleUnwrapAnonymousUser, openLoginModal]}/>
            <Card>
                <div className={classes.root}>
                    <div className={classes.mediaContainer}>
                        <CardMedia
                            className={blur ? classNames(classes.blur, classes.media) : classes.media}
                            image={post.hasAccess && post.contentType === contentTypeEnum.IMAGE ? postFile : post.thumbnail}
                            title={post.title}
                        />
                        {overlay}
                    </div>
                    <div className={classes.content}>
                        <PostHeader post={post}/>
                        <div className={classes.regalo}>
                            {post.ownPost ?
                                <RegaloSet receive={post.value}
                                /> :
                                <RegaloSet user={post.creator.userToMemberRegalo}
                                           member={post.creator.memberToUserRegalo}
                                           send={post.value}
                                />}
                        </div>
                        <CardContent>
                            <Typography paragraph variant="body1" className={classes.description}>
                                {post.description}
                            </Typography>
                        </CardContent>
                    </div>
                </div>
            </Card>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
        textAlign: "left"
    },
    mediaContainer: {
        position: 'relative',
        height: "75vw",
        [theme.breakpoints.up("md")]: {
            width: "75vmin",
            height: "75vmin"
        },
    },
    media: {
        height: "100%",
        width: "100%",
    },
    blur: {
        filter: "blur(4px)",
    },
    mediaOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mediaOverlayCover: {
        backgroundImage: `url(${cover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    content: {
        flex: 1
    },
    regalo: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        borderRadius: 30,
        fontSize: 18,
    },
    disabled: {
        color: "rgba(0, 0, 0, 1)",
        backgroundColor: "rgba(0, 0, 0, 0.3)"
    },
    footer: {
        textAlign: "center",
        padding: 15,
        background: "#eee"
    },
    audioPlayer: {
        width: "100%",
        marginTop: "auto",
    },
    description: {
        whiteSpace: "pre-wrap"
    },
}))
