import * as React from "react"
import {AppBar, IconButton, Toolbar} from "@material-ui/core"
import logo from "../assets/img/amithyst_inv_logo_text.png"
import background from "../assets/img/bg-toned.jpg"
import {Link, useHistory, useLocation} from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles";
import {Auth} from "./user/auth/Auth";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// @ts-ignore
import Flag from "react-flags";
import {Language} from "./lang/Language";
import AccountCircle from "@material-ui/core/SvgIcon/SvgIcon";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";


export function Header() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                {isHomePage ? <></> :
                    <IconButton
                        color="primary"

                        className={classes.iconButton}
                        aria-label="go back"
                        onClick={history.goBack}
                    >
                        <NavigateBeforeIcon/>
                    </IconButton>
                }
                <Link to="/" className={classes.title}>
                    <img src={logo} alt="logo" className={classes.logo}/>
                </Link>
                <Language/>
                {user &&
                <Link to="/messages/">
                    <IconButton color="inherit" aria-label="Login">
                        <i className="fa fa-comment"/>
                    </IconButton>
                </Link>
                }
                <Auth/>
            </Toolbar>
        </AppBar>
    )
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom 25% right 0"
    },
    title: {
        flex: 1,
        
    },
    logo: {
        height: 50,
        marginTop: "auto",
        marginBottom: "auto",
        paddingTop: 4,
        [theme.breakpoints.down("xs")]: {
            height: 40
        }
    },
    iconButton: {
        background: "rgb(255, 255, 255, 0.4)",
        "&:hover": {
            background: "rgb(255, 255, 255, 0.6)",
        }
    }
}))
