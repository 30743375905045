import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../app/store";
import {useParams} from 'react-router-dom';
import {getPost} from "../app/requests/posts";
import {PostDetails} from "../components/posts/PostDetails";
import {Container} from "../components/tools/Container";
import pattern from "../assets/img/background_pattern_opaque.svg";
import {StateLoader} from "../components/tools/StateLoader";


interface RouterParams {
    postId: string
}

export function PostPage() {
    const invoke = useInvoke()
    const params = useParams<RouterParams>()
    const postId = Number(params.postId)
    const {user, postState} = useSelector(
        (state: RootState) => ({
            user: state.user.data,
            postState: state.posts
        }))
    const post = postState.data.find((post) => post.id === postId)
    useEffect(() => {
        invoke.posts.addOne(getPost(postId))
    }, [postId, user])
    return (

        <StateLoader
            state={postState}
            emptySuccessMessage=""
            failureMessage="Failed to load post"
        >
            {post &&
            <Container maxWidth={900} styleUp={{marginTop: 30, marginBottom: 30}}>
                <PostDetails post={post}/>
            </Container>
            }
        </StateLoader>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${pattern})`,
        backgroundSize: "400px 400px",
    }
}))

