import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';

import {getCookie, getToken} from "./credentials";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE"

export class RequestError extends Error {
    status?: number

    constructor(message: string, status?: number) {
        super(message)
        this.status = status
    }
}

export interface WithMessage {
    detail: string
}

export const callEndpoint = <T>(
    url: string,
    method: string,
    request: any = {},
    parser: (data: any) => T = (x: T) => x
): Promise<T> => {
    const csrftoken = getCookie('csrftoken')
    const token = getToken()
    return axios({
        url,
        method,
        mode: "same-origin",
        redirect: "follow",
        credentials: "include",
        ...request,
        headers: {
            'X-CSRFToken': csrftoken,
            'Authorization': token ? 'Token ' + token : "",
            ...(request.headers || {
                "Content-Type": "application/json",
                "Accept": "application/json",
            })
        }
    } as AxiosRequestConfig)
        .then(handleResponse)
        .then((result: any) => parser(result))
        .catch(handleError)
}


const handleError = (error: AxiosError) => {
    const response = error.response
    if (response) {
        let message: string = response.statusText
        const result = response.data
        if (typeof result === 'string') {
            message = result
        } else {
            const keys = Object.keys(result)
            if (result && keys.length) {
                const key = keys[0]
                if (key === "message" || key === "details") {
                    message = result[key].toString()
                } else {
                    message = `${key}: ${result[key].toString()}`
                }
            }
        }
        return Promise.reject(new RequestError(message, response.status))
    }
    return Promise.reject(new RequestError("something went wrong"))
}

const handleResponse = <T>(response: AxiosResponse): Promise<T | void> => {
    if (response.status === 204) return Promise.resolve()
    const contentType = response.headers["content-type"]
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.data
    } else {
        return Promise.reject({})
    }
}

export interface PaginatedResponse<T> {
    results: T[]
    count: number
    previous: string | null
    next: string | null
}

export type HandlePagination = <T>(res: PaginatedResponse<T>) => T[]

export const query2str = (q: any) => Object.keys(q).filter(key => q[key]).map(key => key + "=" + q[key]).join("&")