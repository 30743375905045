import React, {useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import {MainPage} from "./pages/MainPage";
import {AuthModal} from "./components/user/auth/AuthModal";
import {Header} from "./components/Header";
import {NoticeSnackbar} from "./components/tools/NoticeSnackbar";
import {RootState, useInvoke} from "./app/store";
import {getUser} from "./app/requests/auth";
import {makeStyles} from "@material-ui/core/styles";
import {Footer} from "./components/Footer";
import {LynnPage} from "./lynn/pages/LynnPage";
import {PostPage} from "./pages/PostPage";
import {EditPostModal} from "./components/posts/EditPostModal";
import {ProfilePage} from "./pages/ProfilePage";
import pattern from "./assets/img/background_pattern_opaque.svg";
import {Toolbar} from "@material-ui/core";
import {MessagePage} from "./pages/MessagePage";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export function App() {
    const classes = useStyles()
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    const invoke = useInvoke()
    const location = useLocation()
    useEffect(() => {
        invoke.user.load(getUser())
    }, [])
    return (
        <div className={classes.root}>
            <AuthModal/>
            <EditPostModal/>
            <Header/>
            <Toolbar/>
            <Switch>
                {/*<Route exact path="/lynn" component={LynnPage}/>*/}
                <Route path="/posts/:postId" component={PostPage}/>
                {user && <Route path="/messages/:groupId" component={MessagePage}/>}
                {user && <Route exact path="/messages" component={MessagePage}/>}
                <Route path="/@:memberName" component={ProfilePage}/>
                <Route path="/" component={MainPage}/>
            </Switch>
            <NoticeSnackbar/>
            {!location.pathname.includes("/messages") && <Footer/>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: "#fff9ff",
    }
}))

