import {Dispatch} from "redux";
import {ArrayActions} from "./arraySliceTypes";
import {ErrorHandler} from "../store";


export interface ArrayMethods<I> {
    load: (dataPromise: Promise<I[]>) => Promise<I[]>
    clear: () => void
    add: (dataPromise: Promise<I[]>) => Promise<I[]>
    addOne: (dataPromise: Promise<I>) => Promise<I>
    _remove: (dataIds: any[]) => void
    _removeOne: (dataId: any) => void
}

type Element<T> = T extends (infer U)[] ? U : null

export type StoreArrayMethods<S> = {
    [K in keyof S]: ArrayMethods<Element<S[K]>>
}

export const getArrayMethods = <E extends Error>(dispatch: Dispatch, errorHandler: ErrorHandler<E>) =>
    <I>(action: ArrayActions<I[]>): ArrayMethods<I> => {
        const catchError = (error: E) => {
            const errorAction = errorHandler(error)
            if (errorAction) dispatch(errorAction)
            dispatch(action.failed(error.message))
            return Promise.reject(error.message)
        }
        const getBulkAction = (key: keyof ArrayActions<I[]>) => (dataPromise: Promise<I[]>) => {
            dispatch(action.requested())
            return dataPromise.then((data) => {
                dispatch(action[key](data))
                return data
            }).catch(catchError)
        }
        const getSingleAction = (key: keyof ArrayActions<I[]>) => (dataPromise: Promise<I>) => {
            dispatch(action.requested())
            return dataPromise.then((data) => {
                dispatch(action[key]([data]))
                return data
            }).catch(catchError)
        }
        return {
            load: getBulkAction("loaded"),
            clear: () => dispatch(action.init()),
            add: getBulkAction("updated"),
            addOne: getSingleAction("updated"),
            _remove: (dataId: any[]) => dispatch(action.removed(dataId)),
            _removeOne: (dataId: any) => dispatch(action.removed([dataId])),
        }
    }