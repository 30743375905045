import React from 'react';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {UserAvatar} from "../user/UserAvatar";
import {User} from "../../app/models/User";
import {Member} from "../../app/models/Member";


interface Props {
    selectedId: number | string
    items: NotificationListItem[]
}

export interface NotificationListItem {
    id: number | string
    user: User | Member
    primary: React.ReactNode | string
    secondary: React.ReactNode | string
}

const renderListItem = (selectedId: number | string) => (item: NotificationListItem) => (
        <React.Fragment key={item.id}>
            <ListItem alignItems="flex-start" button selected={item.id === selectedId}>
                <ListItemAvatar>
                    <UserAvatar user={item.user}/>
                </ListItemAvatar>
                <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                />
            </ListItem>
            <Divider component="li"/>
        </React.Fragment>
    )

export function NotificationList(props: Props) {
    const classes = useStyles();
    const {items} = props

    return (
        <List className={classes.root}>
            {items.map(renderListItem(props.selectedId))}
        </List>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    }),
);
