import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Timeline} from "../components/posts/Timeline";
import {TabDisplay} from "../components/tools/TabDisplay";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../app/store";
import {Fab} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create"
import {Link, useRouteMatch} from 'react-router-dom';
import {Container} from "../components/tools/Container";
import logo from '../assets/img/amithyst_logo.png'
import pattern from '../assets/img/background_pattern_opaque.svg'
import {AboutPage} from "./AboutPage";


export function MainPage() {
    const classes = useStyles();
    const match = useRouteMatch()
    const {user, lang} = useSelector((state: RootState) => ({
        user: state.user.data,
        lang: state.lang.data
    }))
    const invoke = useInvoke()
    const openAuth = () => invoke.authModalOpen._load(true)
    const editModalButton =
        <Fab variant="round" color="primary" onClick={() => invoke.postModal._load(true)}>
            <CreateIcon/>
        </Fab>
    return (
        <div className={classes.root}>
            {!user &&
            <div className={classes.banner}>
                <Container maxWidth={800}>
                    <div className={classes.bannerContent}>
                        <div className={classes.logo}/>
                        <div className={classes.message}>
                            <div>
                                <h3 className={classes.messageHeader}>Let your creative skills shine</h3>
                                <div className={classes.messageContent}>
                                    {{
                                        EN: <div>
                                            <p>This is where you can showcase your creative work, knowledge and ideas.
                                                We envisage a community where everyone is a giver and a creator in some
                                                way,
                                                and where every piece of creative work is regarded with kindness and
                                                respect.
                                            </p>
                                        </div>,
                                        JP: <div>
                                            <p>アミシストは、クリエイティブな作品を持ち寄るコミュニティです。
                                                意外にも身近なところに価値あるものはあふれています。</p>
                                            <p>作品を作ってくれた人には「レガロ」の贈り物をして、
                                                やさしさを与え合う世界を作りませんか？
                                            </p>
                                        </div>
                                    }[lang]}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            }
            <TabDisplay
                parentPath={match.url}
                defaultTab={user ? 1 : 0}
                items={[{
                    label: "About",
                    link: "/about",
                    content: <AboutPage/>
                }, {
                    label: "Discover",
                    link: "/posts",
                    fab: user && editModalButton,
                    content: <Timeline/>
                }, {
                    label: "My Collections",
                    link: "/collections",
                    onClick: user ? undefined: openAuth,
                    fab: user && editModalButton,
                    content: <Timeline hasPurchased={true}/>
                }]}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        background: theme.palette.secondary.light
    },
    link: {
        textDecoration: "underline"
    },
    banner: {
        background: "#fff9ff",
        backgroundImage: `url(${pattern})`,
        backgroundSize: "400px 400px",
        color: "#707"
    },
    bannerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            paddingTop: 20
        },
    },
    title: {
        flexGrow: 1,
    },
    message: {
        fontWeight: "bold",
        flex: 1,
        display: "flex",
        alignItems: "flex-end",
        textAlign: "left",
        padding: 20,
        fontFamily: "'Cardo', 'Sawarabi Mincho', serif",
        fontStyle: "italic",
    },
    messageHeader: {
        fontSize: 22,
    },
    messageContent: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14
        },
    },
    logo: {
        height: "40vmin",
        width: "40vmin",
        marginTop: 40,
        marginBottom: 40,
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    }
}));
