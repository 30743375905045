import * as React from "react"
import CreateIcon from "@material-ui/icons/Create"
import {makeStyles} from "@material-ui/core/styles";
import {EmptyContent} from "../components/tools/EmptyContent";
import {TabDisplay} from "../components/tools/TabDisplay";
import {useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../app/store";
import {useEffect} from "react";
import {getMemberByUsername} from "../app/requests/members";
import {Timeline} from "../components/posts/Timeline";
import {Fab} from "@material-ui/core";
import {ProfileBanner} from "../components/user/ProfileBanner";
import {StateLoader} from "../components/tools/StateLoader";

interface RouterParams {
    memberName: string
}

export function ProfilePage() {

    const classes = useStyles()
    const params = useParams<RouterParams>()
    const match = useRouteMatch()
    const memberName = params.memberName
    const invoke = useInvoke()
    const {user, memberState} = useSelector((state: RootState) => ({
        user: state.user.data,
        memberState: state.members
    }))
    const member = memberState.data.find((member) => member.username === memberName)
    useEffect(() => {
        invoke.members.addOne(getMemberByUsername(memberName))
    }, [memberName, user])

    const editModalButton =
        <Fab variant="round" color="primary" onClick={() => invoke.postModal._load(true)}>
            <CreateIcon/>
        </Fab>
    return (
        <StateLoader
            state={memberState}
            emptySuccessMessage=""
            failureMessage="Cannot load member profile"
        >
            {member ?
                <div className={classes.root}>
                    <ProfileBanner member={member}/>
                    <TabDisplay
                        tabsClasses={{
                            root: classes.tabs
                        }}
                        parentPath={match.url}
                        defaultTab={0}
                        items={[{
                            label: "Posts",
                            link: "/posts",
                            fab: user && user.username === memberName && editModalButton,
                            content: <Timeline thisMember={member}/>
                        }]}
                    />
                </div>
                :
                <></>
            }
        </StateLoader>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        background: theme.palette.secondary.light
    },
    tabHeader: {
        width: 200
    },
    tabs: {
        [theme.breakpoints.up("md")]: {
            marginLeft: 160
        }
    }
}))
