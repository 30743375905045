import * as React from "react"
import {Theme, WithStyles, withStyles, createStyles, Button} from "@material-ui/core"
import {connect} from "react-redux";
import {User} from "../../app/models/User";
import {Member} from "../../app/models/Member";
import {getPosts, PostFilterParams} from "../../app/requests/posts";
import {Post} from "../../app/models/Post";
import {needsRefresh} from "../../app/requests/auth";
import {mapDispatch, RootState} from "../../app/store";
import {StoreItemState} from "../../redux/types";
import {Dispatch} from "react";
import {ObjectMethods} from "../../redux/object/objectMethods";
import {ArrayMethods} from "../../redux/array/arrayMethods";
import {StateLoader} from "../tools/StateLoader";
import PostCard from "./PostCard";
import {TiledView} from "../tools/TiledView";
import {sortByUpdatedDate} from "../../utils/sort";
import {PaginatedResponse} from "../../app/requests/utils/requests";

interface StoreProps {
    userState: StoreItemState<User>
    postState: StoreItemState<Post[]>
}

interface DispatchProps {
    methods: {
        user: ObjectMethods<User>
        posts: ArrayMethods<Post>
    }
}

interface Props extends StoreProps, DispatchProps, WithStyles<typeof styles> {
    thisMember?: User | Member
    hasPurchased?: boolean
}

interface State extends PostFilterParams {
    page: number
    count: number
    showMore: boolean
}

class TimelineComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            page: 1,
            count: 0,
            showMore: false
        }
    }

    public render() {

        const {classes, postState, hasPurchased, thisMember} = this.props
        const {showMore} = this.state
        const posts = postState.data.filter((post: Post) => {
            if (thisMember && post.creator.username !== thisMember.username) return false
            if (hasPurchased === undefined) return true
            return hasPurchased === post.hasPurchased
        })
        const renderer = (post: Post) => <PostCard key={post.id} post={post}/>
        const sortedPostItems = posts.slice().sort(sortByUpdatedDate as any)
            .map((post) => ({
                key: post.id,
                data: post
            }))
        return (
            <StateLoader
                state={postState}
                emptySuccessMessage="No posts to show."
                failureMessage="Failed to load posts"
            >
                <div className={classes.container}>
                    <TiledView items={sortedPostItems} renderer={renderer} tileWidth={320} tileMargin={5}/>
                    {showMore &&
                    <Button color="primary" onClick={this.handleShowMore}>
                        Show More
                    </Button>
                    }
                </div>
            </StateLoader>
        )
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false);
        this.loadPosts()
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (
            needsRefresh(this.props.userState, prevProps.userState) ||
            this.props.postState.status === "INIT" ||
            this.props.hasPurchased !== prevProps.hasPurchased ||
            this.props.thisMember !== prevProps.thisMember
        ) {
            this.loadPosts()
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    private readonly onScroll = () => {
        const {showMore} = this.state
        const {postState} = this.props
        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) &&
            showMore && postState.status !== "REQUESTED"
        ) {
            this.loadPosts(false)
        }
    }

    private readonly loadPosts = (reset: boolean = true) => {
        const {showMore} = this.state
        let {page} = this.state
        if (reset || showMore) {
            const {thisMember, methods, hasPurchased} = this.props
            page = reset ? 1 : page + 1
            const creator = thisMember ? thisMember.username : undefined
            this.setState({page})
            const {load, add} = methods.posts
            const loader = reset ? load : add
            loader(getPosts({creator, hasPurchased})(page).then((res: PaginatedResponse<Post>) => {
                this.setState({count: res.count, showMore: !!res.next})
                return res.results || []
            }))
        }
    }

    private readonly handleShowMore = () => {
        this.loadPosts(false)
    }
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: 5,
        paddingBottom: 10
    },
})

const mapStateToProps = (state: RootState): StoreProps => ({
    userState: state.user as StoreItemState<User>,
    postState: state.posts as StoreItemState<Post[]>,
})

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    const methods = mapDispatch(dispatch as any)
    return ({
        methods: {
            user: methods.user as ObjectMethods<User>,
            posts: methods.posts as ArrayMethods<Post>,
        }
    })
}

export const Timeline = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimelineComponent))
