import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {mapValues} from "lodash"
import {StoreArrayActions} from "./arraySliceTypes";
import {getArrayMethods, StoreArrayMethods} from "./arrayMethods";
import {Dispatch} from "redux";
import {ErrorHandler} from "../store";
import {DraftStoreItemState, StoreItemState, StoreReducers, storeStatus} from "../types";

export type GetId = (e: any) => string | number

export const createArraySlice = <I, K extends string>(getId: GetId) => (arrayInit: I[], name: K) => {
    type State = StoreItemState<I[]>
    type DraftState = DraftStoreItemState<I[]>
    const initialState = {
        data: arrayInit,
        status: storeStatus.INIT,
        message: null
    } as State

    const filterRedundantData = (state: DraftState, dataIds: any[]) =>
        state.data.filter((item: Draft<I>) => !dataIds.includes(getId(item)))

    return createSlice({
        name,
        initialState,
        reducers: {
            init: () => initialState,
            requested: (state: DraftState) => {
                state.status = storeStatus.REQUESTED
                state.message = null
            },
            loaded: (state: DraftState, action: PayloadAction<Draft<I[]>>) => {
                state.data = action.payload
                state.status = storeStatus.SUCCESS
                state.message = null
            },
            updated: (state: DraftState, action: PayloadAction<Draft<I[]>>) => {
                const newData = action.payload
                const newDataIds = newData.map(getId)
                const keepData = filterRedundantData(state, newDataIds)
                state.data = keepData.concat(newData)
                state.status = storeStatus.SUCCESS
                state.message = null
            },
            removed: (state: DraftState, action: PayloadAction<Draft<any[]>>) => {
                const removeDataIds = action.payload
                state.data = filterRedundantData(state, removeDataIds)
                state.status = storeStatus.SUCCESS
                state.message = null
            },
            failed: (state: DraftState, action: PayloadAction<string>) => {
                state.status = storeStatus.FAILURE
                state.message = action.payload
            }
        }
    })
}

export const getStoreArrayMethods = <S extends object, E extends Error>(storeArrayInit: S, getId: GetId) => {
    const arraySlices = mapValues(storeArrayInit, createArraySlice(getId))
    const arrayActions = mapValues(arraySlices, (slice: any) => slice.actions) as StoreArrayActions<S>
    const arrayReducers = mapValues(arraySlices, (slice: any) => slice.reducer) as StoreReducers<S>
    const arrayMethods = (dispatch: Dispatch, errorHandler: ErrorHandler<E>) => {
        return mapValues(arrayActions, getArrayMethods(dispatch, errorHandler)) as any as StoreArrayMethods<S>
    }
    return {arrayActions, arrayReducers, arrayMethods}
}