import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {avatars, regaloVariant, tooltipInfo} from "./Regalo";
import {Avatar, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";

export function RegaloTable() {
    const classes = useStyles();
    const {lang} = useSelector((state: RootState) => ({
        lang: state.lang.data
    }))
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{{
                            EN: "Symbol",
                            JP: "アイコン"
                        }[lang]}</TableCell>
                        <TableCell>{{
                            EN: "Meaning",
                            JP: "意味"
                        }[lang]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regaloVariant.map((variant) => (
                        <TableRow key={variant}>
                            <TableCell className={classes.regaloCell}>
                                <Avatar className={classes.regalo}>
                                    <img src={avatars[variant]} alt={variant} style={{width: "100%"}}/>
                                </Avatar>
                            </TableCell>
                            <TableCell>
                                <Typography>{tooltipInfo[variant][lang]}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles({
    regalo: {
        background: "white",
    },
    regaloCell: {
        display: "flex",
        justifyContent: "center"
    },
});
