import {combineReducers, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {getStoreObjectMethods} from "./object/objectSlice";
import {useDispatch} from "react-redux";
import {StoreObjectMethods} from "./object/objectMethods";
import {StoreObjectActions} from "./object/objectSliceTypes";
import {StoreState} from "./types";
import {GetId, getStoreArrayMethods} from "./array/arraySlice";
import {StoreArrayActions} from "./array/arraySliceTypes";
import {StoreArrayMethods} from "./array/arrayMethods";

export type Actions<SO, SA> = StoreObjectActions<SO> & StoreArrayActions<SA>
export type Invoke<SO, SA> = StoreObjectMethods<SO> & StoreArrayMethods<SA>
export type State<SO, SA> = StoreState<SO> & StoreState<SA>

export type ErrorHandler<E extends Error> = (e: E) => PayloadAction<any> | null
export type ConfigErrorHandler<SO extends object, SA extends object, E extends Error> = (actions: Actions<SO, SA>) => ErrorHandler<E>

export function setupRedux<SO extends object, SA extends object, E extends Error>(
    storeObjInit: SO,
    storeArrayInit: SA,
    getId: GetId,
    configErrorHandler: ConfigErrorHandler<SO, SA, E> = (a) => (e) => null
) {
    const {objectActions, objectReducers, objectMethods} = getStoreObjectMethods<SO, E>(storeObjInit)
    const {arrayActions, arrayReducers, arrayMethods} = getStoreArrayMethods<SA, E>(storeArrayInit, getId)
    const reducer = combineReducers({...objectReducers, ...arrayReducers})
    const actions = {...objectActions, ...arrayActions}
    const methods = (dispatch: Dispatch, errorHandler: ErrorHandler<E>) =>
        ({...objectMethods(dispatch, errorHandler), ...arrayMethods(dispatch, errorHandler)})
    const mapDispatch = (dispatch: Dispatch) => methods(dispatch, configErrorHandler(actions))
    const useInvoke = (): Invoke<SO, SA> => {
        const dispatch = useDispatch()
        return mapDispatch(dispatch)
    }
    return {reducer, actions, methods, useInvoke, mapDispatch}
}
