import React from 'react';
import regalo_mine from "../../assets/img/regalo/regalo_mine.png";
import regalo_theirs from "../../assets/img/regalo/regalo_theirs.png";
import regalo_send from "../../assets/img/regalo/regalo_send.png";
import regalo_receive from "../../assets/img/regalo/regalo_receive.png";
import {Avatar, Theme, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {Translations} from "../lang/Language";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";

export type Variant = "mine" | "theirs" | "send" | "receive"
export type Size = "small" | "normal" | "large"
export const regaloVariant: Variant[] = ["mine", "theirs", "send", "receive"]

interface Props {
    variant: Variant
    value: number
    size?: Size
}

type Avatars = {
    [v in Variant]: string
}

type TooltipInfo = {
    [v in Variant]: Translations
}

export const tooltipInfo: TooltipInfo = {
    "mine": {
        EN: "Regalo you have for the user",
        JP: "あなたが持っている相手のためのレガロ"
    },
    "theirs": {
        EN: "Regalo the user has for you",
        JP: "相手が持っているあなたのためのレガロ"
    },
    "send": {
        EN: "Regalo you need to view their work",
        JP: "相手の作品を見るために必要なレガロ"
    },
    "receive": {
        EN: "Regalo people need to give to you to view your work",
        JP: "あなたの作品を見るために必要なレガロ"
    }
}

export const avatars: Avatars = {
    "mine": regalo_mine,
    "theirs": regalo_theirs,
    "send": regalo_send,
    "receive": regalo_receive
}

export const Regalo = (props: Props) => {
    const classes = useStyles();
    const {value, variant, size} = props
    const avatar = avatars[variant]
    const avatarSize: Size = size ? size : "normal"
    const fontSize = {
        "small": 18,
        "normal": 20,
        "large": 24
    }
    const {lang} = useSelector((state: RootState) => ({
        lang: state.lang.data
    }))
    return (
        <Tooltip title={tooltipInfo[variant][lang] as string} aria-label={tooltipInfo[variant][lang] as string} placement="top">
            <div className={classes.root}>
                <Avatar className={classNames(classes.regalo, classes[avatarSize])}>
                    <img src={avatar} alt={variant} style={{width: "100%"}}/>
                </Avatar>
                <div className={classes.value} style={{fontSize: fontSize[avatarSize]}}>
                    {value}
                </div>
            </div>
        </Tooltip>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 85,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    regalo: {
        background: "rgba(255, 255, 255, 0)",
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    normal: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    value: {
        display: "flex",
        alignItems: "center",
        marginLeft: 5,
        fontWeight: "bold",
        fontSize: 20,
        color: theme.palette.primary.dark
    }
}))
