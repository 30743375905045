import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    open: boolean
    handleClose: () => void
    title?: string
    message: React.ReactNode
    actions: string[]
    handleActions: (() => void)[]
}

export function ModalDialog(props: Props) {
    const {open, handleClose, title, message, actions, handleActions} = props
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
        >
            {title &&
            <DialogTitle>{title}</DialogTitle>
            }
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus className={classes.cancelButton}>
                    Cancel
                </Button>
                {actions.map((action, i) => {
                    const handleAction = () => {
                        handleActions[i]()
                        handleClose()
                    }
                    return <Button key={action} onClick={handleAction} color="primary">
                        {action}
                    </Button>
                })}
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left"
    },
    cancelButton: {
        marginRight: "auto"
    }
}))