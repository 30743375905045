import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "../components/tools/Container";
import {Button, Typography} from "@material-ui/core";
import {RegaloTable} from "../components/regalo/RegaloTable";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";
import {Link} from "react-router-dom";


export function AboutPage() {
    const classes = useStyles()
    const {lang} = useSelector((state: RootState) => ({
        lang: state.lang.data
    }))
    const Br = () => <br className={classes.separator}/>
    return (
        <Container maxWidth={800}>
            <div className={classes.root}>
                <Br/>
                <Typography variant="h3" component="h2">
                    {{
                        EN: "How it works",
                        JP: "Amithystとは"
                    }[lang]}
                </Typography>
                <div className={classes.content}>
                    {{
                        EN: <>
                            <p>Amithyst is a platform where you can showcase your skills and
                                creative work.
                                This can range from your artwork, composition, poetry, or other forms of creativity.</p>

                            <p>Whether or not others can view your work is governed by tokens called <b>Regalo</b>.
                                When you view a piece of creative work, the creator will receive Regalo as a token
                                of gratitude.
                                Having received Regalo, the creator can now view your work.</p>
                            <p>
                                How much Regalo do we have in the first place, you might ask?
                                Everyone starts with zero Regalo, but Regalo are specific to individual users,
                                rather than a universal currency.
                                This means there aren't any rich or poor people in this community.
                                Rather, it is about building mutual appreciation.
                                The amount of Regalo between two users reflect the amount of interaction they have had,
                                and is only visible to the two of them.</p>
                            <p>Since everyone starts with no Regalo, the first work you post must be viewable by
                                everyone
                                (i.e., you claim 0 Regalo from the viewer).
                                For every view you get, you earn an extra Regalo as a token for your hard work.
                                Having earned Regalo, you can start viewing other people's amazing work,
                                and also start claiming more Regalo for your prized creations!
                            </p>
                        </>,
                        JP: <>
                            <p>アミシストは、作品の共有と鑑賞で互いのトークンが増えるクリエイターマーケットです。
                                絵、デザイン、作曲、詩、エッセイ…、誰もが何かしら素晴らしい趣味や才能を持っています。
                                アミシストは、そんなひとりひとりの素敵な才能がきらめく場を提供します。</p>
                            <p>作品を鑑賞するごとに、作品の製作者にはレガロというトークンが与えられます。
                                レガロをもらうことで、今度は作品の制作者があなたの作品を鑑賞する権利を得られます。
                                ひとつひとつの作品には鑑賞するのに必要なレガロの額を設定できます。</p>
                            <p>レガロの特徴は二つあります。ひとつは、作品が閲覧されるたびに、新たなレガロが1つ生み出されること。
                                もうひとつは、レガロの価値がひとえに二人のユーザの関係のなかで定義されていること。
                                普遍的な通貨としての「お金」とは違い、レガロはあるユーザが別のあるユーザと交流する中で生まれます。
                                だからこそ、アミシストは、お金持ちと貧乏が生まれる世界ではなく、
                                相互の信頼関係と交流関係に基づき、誰もが評価されるチャンスのあるフェアな世界を目指しています。</p>
                            <p>
                                最初はレガロをもっていないところからスタートなので、まずは皆がお試しで鑑賞できる作品を投稿してみましょう。
                            </p>
                        </>
                    }[lang]}
                    <RegaloTable/>
                </div>
                <Br/>
                <Typography variant="h3" component="h2">
                    {{
                        EN: "Naming",
                        JP: "名前の由来"
                    }[lang]}
                </Typography>
                <div className={classes.content}>
                    {{
                        EN: <>
                            <p>The naming of <b>Amithyst</b> conbines the Latin root <i>ami-</i> which means love
                                and friendship, and the purple gemstone <i>Amethyst</i>.
                                It symbolises the crystallisation of creative ideas and discovering the beauty
                                and value within each other. Amethyst itself has been believed
                                to have powers of purification and to bring people together.
                            </p>

                            <p>The name of the token <b>Regalo</b> means "gift" in Italian, Spanish and Portuguese.
                                Send your Regalo as a token of gratitude -
                                <i>"Thank you for creating such fantastic piece of work.
                                    This is a small gift for you."</i>
                            </p>
                        </>,
                        JP: <>
                            <p>Amithystの由来はラテン語で友情や愛を意味するAmiと、紫色に輝く宝石「アメジスト」の掛け合わせ。
                                宝石には「原石を互いに磨きあっていくことで輝きを増していく」という思いがあり、
                                アメジストに「場を浄化する」「人を呼び込む」効能があると言われています。
                                ロゴはアメジストの原石をイメージしました。</p>
                            <p>トークン名のレガロはイタリア語、スペイン語、ポルトガル語で「贈り物」の意味。
                                『素敵な作品を生み出してくれてありがとう、私なりの贈り物を。』そんな気持ちでレガロを贈ってみてください。
                            </p>
                        </>
                    }[lang]}

                </div>
                <Br/>
                <Typography variant="h3" component="h2">
                    {{
                        EN: "Community rules",
                        JP: "注意事項"
                    }[lang]}
                </Typography>
                <div className={classes.content}>
                    {{
                        EN: <p>
                            Currently we support images (jpg, png, gif), audio (mp3, wav) and PDF files.
                            For keeping the community healthy and meaningful, please refrain from posting content that
                            is not created by yourself, or is harmful, unethical or disrespectful in any way.
                            Any content posted here must be treated with respect, and must not be redistributed without
                            permission from the creator.
                        </p>,
                        JP: <p>
                            現時点でサポートされているファイル形式は、画像（jpg, png, gif）、オーディオ（mp3, wav）とPDFです。
                            他の人が作った作品を盗用するような投稿や、誹謗中傷にあたる投稿、非道徳的と思われる投稿はおやめください。
                            このコミュニティに投稿された作品には敬意をもって接するようお願いいたします。
                            投稿された作品を制作者の許可なしに再配布することはできません。
                        </p>
                    }[lang]}
                </div>
                <Br/>
                <div><Link to="/posts"><Button variant="contained" color="primary">{{
                    EN: "Start browsing",
                    JP: "作品一覧を見る"
                }[lang]}</Button></Link></div>
                <Br/>
            </div>
        </Container>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 30,
        color: "#505",
    },
    content: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: "left",
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        },
    },
    separator: {
        marginTop: 10,
        clear: "both"
    }
}));
