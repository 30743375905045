import {callEndpoint, query2str, HandlePagination, PaginatedResponse} from "./utils/requests";
import {ContentType, Post, PostState} from "../models/Post";
import {ObjectId} from "../models/utils/types";

const formatPostFormData = (post: PostState) => {
    const formData = new FormData()
    Object.keys(post).forEach((key) => {
        console.log(key)
        console.log(post[key as keyof PostState])
        const value = post[key as keyof PostState]
        if (value !== undefined && value !== null) {
            if (key === 'file')
                formData.append('file', post.file!)
            else
                formData.append(key as string, post[key as keyof PostState]!.toString())
        }
    })
    return formData
}

export const createPost = (post: PostState) =>
    callEndpoint<Post>("/api/posts/", "POST", {
        data: formatPostFormData(post),
        headers: {}
    })

export const updatePost = (post: PostState) =>
    callEndpoint<Post>(`/api/posts/${post.id}/`, "PATCH", {
        data: formatPostFormData(post),
        headers: {}
    })

export const deletePost = (postId: ObjectId) =>
    callEndpoint<void>(`/api/posts/${postId}/`, "DELETE")

export interface PostFilterParams {
    creator?: string
    hasPurchased?: boolean
}

export const purchaseProduct = (id: ObjectId) =>
    callEndpoint<Post>(`/api/posts/${id}/purchase/`, "PUT")

export const getPost = (id: ObjectId) =>
    callEndpoint<Post>(`/api/posts/${id}/`, "GET")

export const getPosts = (queryParams: PostFilterParams) => (page: number) =>
    callEndpoint<PaginatedResponse<Post>>(`/api/posts/?page=${page}&${query2str(queryParams)}`, "GET")