import * as React from "react"
import {CardMedia} from "@material-ui/core"
import classNames from "classnames"
import {uploadFile} from "../../app/requests/files";
import {FileModel} from "../../app/models/File";
import {makeStyles} from "@material-ui/core/styles";
import {useInvoke} from "../../app/store";
import {noticeTypeEnum} from "../../app/models/utils/types";

interface Props {
    thumbnail?: string
    handleChange: (thumbnail: FileModel, file: File) => void
    bgImage: string
    children?: React.ReactNode
}

export function ImageFileUpload(props: Props) {
    const {thumbnail, handleChange, bgImage, children} = props
    const classes = useStyles()
    const invoke = useInvoke()

    const fileChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files.length) {
            const file: File = files[0]
            uploadFile(file).then((thumbnail) => handleChange(thumbnail, file))
                .catch((error) => invoke.notice._load({type: noticeTypeEnum.ERROR, message: error.message}))
        }
    }

    const image = thumbnail ?
        <CardMedia
            className={classes.box}
            image={thumbnail}
            title="Add thumbnail"
        />
        :
        <div className={classNames(classes.box, classes.addImage)} style={{backgroundImage: `url(${bgImage})`}}>
            {children}
        </div>

    return (
        <label className={classes.box}>
            {image}
            <input type="file" onChange={fileChangedHandler} className={classes.hide}/>
        </label>
    )
}

const useStyles = makeStyles((theme) => ({
    box: {
        width: "100%",
        height: "100%",
        display: "block",
        cursor: "pointer"
    },
    hide: {
        display: "none"
    },
    addImage: {
        backgroundColor: theme.palette.primary.light,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    }
}))