import {User} from "../models/User"
import {callEndpoint, WithMessage} from "./utils/requests";
import {storeToken, Token} from "./utils/credentials";
import {StoreItemState} from "../../redux/types";

export const getUser = () => callEndpoint<User>("/rest-auth/user/", "GET", {})

export const login = (username: string, password: string): Promise<User> =>
    callEndpoint<Token>("/rest-auth/login/", "POST", {
        data: { username, password }
    }).then(storeToken).then(getUser)

export const logout = () =>
    callEndpoint<string>("/rest-auth/logout/", "POST").then(() => {
        localStorage.clear()
        window.location.reload()
    })

export const register = (username: string, email: string, password: string) =>
    callEndpoint<User>("/rest-auth/registration/", "POST",{
        data: { username, email, password1: password, password2: password }
    }).then(() => login(username, password))

export const forgotPassword = (email: string) =>
    callEndpoint<WithMessage>("/rest-auth/password/reset/", "POST", {
        data: { email }
    })

export const verify = (email: string, token: string): Promise<User> =>
    callEndpoint<User>("/api/auth/Verify/", "POST", {
        data: { email, token }
    })

export const changePassword = (oldPassword: string, newPassword: string): Promise<WithMessage> =>
    callEndpoint<WithMessage>("/api/auth/changePassword", "PUT", {
        data: { oldPassword, newPassword }
    })

export const resetPassword = (uid: string, password: string, token: string): Promise<User> =>
    callEndpoint<User>("/rest-auth/password/reset/confirm/", "PUT", {
        data: { uid, token, new_password1: password, new_password2: password }
    })

export const updateUser = (data: Partial<User>): Promise<User> =>
    callEndpoint<User>("/rest-auth/user/", "PATCH", {
        data
    })

export const needsRefresh = (currUser: StoreItemState<User>, prevUser: StoreItemState<User>) => {
    const currStatus = currUser.status
    const prevStatus = prevUser.status
    return currStatus !== prevStatus && (
        currStatus === "SUCCESS" || currStatus === "FAILURE" ||
        (currStatus === "INIT" && prevStatus === "SUCCESS")
    )
}