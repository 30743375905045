import * as React from "react"
import {
    ClickAwayListener,
    Grow,
    MenuList,
    Paper,
    Popper, PopperProps
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";


interface Props {
    menuItems: React.ReactNode[]
    toggle: boolean
    setToggle: (toggle: React.SetStateAction<boolean>) => void
    anchorRef: React.RefObject<any>
    popperProps?: PopperProps
}


export function Dropdown(props: Props) {
    const classes = useStyles()
    const {toggle, setToggle, menuItems, anchorRef, popperProps} = props
    const handleClose = () => setToggle(false)
    return (
        <Popper placement="bottom-end" className={classes.popper} open={toggle} anchorEl={anchorRef.current}
                transition disablePortal {...popperProps}>
            {({TransitionProps}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: 'center top'}}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={toggle} onClick={handleClose}>
                                {menuItems}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: 9999
    }
}))