import * as React from "react"
import {
    TextField,
    DialogActions,
    Button,
    Theme,
    WithStyles,
    withStyles,
    createStyles,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    DialogContent, Dialog, InputLabel, Select, MenuItem, FormHelperText
} from "@material-ui/core"
import {Post, PostState, contentTypeEnum} from "../../app/models/Post";
import {connect} from "react-redux";
import {mapDispatch, RootState} from "../../app/store";
import {Dispatch} from "react";
import {createPost, updatePost} from "../../app/requests/posts";
import {ArrayMethods} from "../../redux/array/arrayMethods";
import {ImageFileUpload} from "../forms/ImageFileUpload";
import {FileModel} from "../../app/models/File";
import addImage from "../../assets/img/icons/addImage.png"
import {User} from "../../app/models/User";
import {range} from "../../utils/array";
import {Regalo} from "../regalo/Regalo";
import {ObjectMethods} from "../../redux/object/objectMethods";
import {Notice, noticeTypeEnum} from "../../app/models/utils/types";
import {getUser} from "../../app/requests/auth";

interface Props extends WithStyles<typeof styles>, StoreProps, DispatchProps {
}

interface State extends PostState {
}

class EditPostModalComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = this.getInitialState(this.props.modalPost)
    }

    public render() {
        const {classes, handleClose, user} = this.props
        const {id, thumbnail, title, description, category, contentType, value, file} = this.state

        const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()
        const maxRegalo = user ? Math.min(user.posts.length, 5) : 0
        const regaloChoices =
            range(0, maxRegalo).map((v) => (
                <MenuItem key={v} value={v}>{v}</MenuItem>
            ))

        return (
            <Dialog disableAutoFocus scroll="body" open={!!this.props.modalPost} onClick={stopPropagation}>
                <DialogContent>
                    <div className={classes.thumbnail}>
                        <ImageFileUpload thumbnail={thumbnail} handleChange={this.handleThumbnailChange}
                                         bgImage={addImage}/>
                    </div>
                    <TextField
                        name="title" required fullWidth inputProps={{style: {width: "100%"}}}
                        label="Title - give your art work a name" value={title} onChange={this.handleChange}/>
                    <TextField
                        name="description" label={"An overview of what you are contributing"}
                        placeholder={""} fullWidth required multiline
                        rows="10" rowsMax="20" value={description} onChange={this.handleChange}/>
                    <FormControl component="fieldset">
                        <RadioGroup name="contentType" value={contentType}
                                    onChange={this.handleChange}
                                    style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                            <FormControlLabel value={contentTypeEnum.IMAGE} control={<Radio color="primary"/>}
                                              label="Image (drawing, picture, design, etc.)"/>
                            <FormControlLabel value={contentTypeEnum.AUDIO} control={<Radio color="primary"/>}
                                              label="Audio (music, etc.)"/>
                            <FormControlLabel value={contentTypeEnum.PDF} control={<Radio color="primary"/>}
                                              label="PDF (documents, music sheet, etc.)"/>
                        </RadioGroup>
                    </FormControl>
                    <div className={classes.fileUpload}>
                        <Button variant="contained" color="secondary" component="label">
                            Upload File
                            <input type="file" style={{display: "none"}} onChange={this.handleFileUpload}/>
                        </Button>
                        <span style={{marginLeft: 10}}>{file && file.name}</span>
                    </div>
                    <div className={classes.regaloBox}>
                        <Regalo variant="receive" value={value}/>
                        <FormControl className={classes.regaloSelect}>
                            <InputLabel id="regalo">Regalo</InputLabel>
                            <Select
                                inputProps={{
                                    name: 'value',
                                }}
                                labelId='regalo'
                                value={value}
                                onChange={this.handleChange}
                            >
                                {user && user.posts.length > 0 ? regaloChoices :
                                <MenuItem value={0}>0 - You can claim more Regalo once you have your first post</MenuItem>}
                            </Select>
                            <FormHelperText>Amount of Regalo needed for others to view your content</FormHelperText>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={this.handleSubmit}>
                        {id ? "Update" : "Post"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (!!this.props.modalPost && !prevProps.modalPost) {
            this.setState(this.getInitialState(this.props.modalPost))
        }
    }

    private readonly handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        const name = event.target.name as keyof PostState
        const value = event.target.value
        this.setState({
            [name]: value
        } as object)
        if (name === "contentType") {
            this.setState({file: undefined})
        }
    }

    private readonly handleThumbnailChange = (thumbnail: FileModel, file: File) => {
        this.setState({thumbnail: thumbnail.image})
        if (this.state.contentType === contentTypeEnum.IMAGE) {
            this.setState({file})
        }
    }

    private readonly handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files.length) {
            const file: File = files[0]
            this.setState({file})
        }
    }

    private readonly validateFileType = (fileType: string) => {
        const contentType = this.state.contentType
        if (contentType === contentTypeEnum.IMAGE) return fileType.includes("image")
        else if (contentType === contentTypeEnum.AUDIO) return fileType.includes("audio")
        else if (contentType === contentTypeEnum.PDF) return fileType.includes("pdf")
        return true
    }

    private readonly handleSubmit = () => {
        const {id, ...postState} = this.state
        const file = postState.file
        if (file) {
            if (!this.validateFileType(file.type)) {
                this.props.methods.notice._load({type: noticeTypeEnum.ERROR,
                    message: "Uploaded file must match the content type you have selected"})
            }
        } else if (!id) {
            this.props.methods.notice._load({type: noticeTypeEnum.ERROR, message: "Upload file not selected"})
        }
        this.props.methods.posts.addOne(id ? updatePost({id, ...postState}) : createPost(postState))
            .then(() => this.props.methods.user.load(getUser()))
            .then(this.props.handleClose)
    }

    private readonly getInitialState = (post: Post | boolean | undefined): State => {
        if (post && (post !== true)) {
            return {
                ...post,
                file: undefined
            }
        }
        return {
            id: undefined,
            thumbnail: undefined,
            title: "",
            description: "",
            contentType: contentTypeEnum.IMAGE,
            file: undefined,
            value: 0
        }
    }
}

const styles = (theme: Theme) => createStyles({
    actions: {
        textAlign: "right",
        background: "#ccc"
    },
    thumbnail: {
        height: 200
    },
    regaloBox: {
        display: "flex"
    },
    regaloSelect: {
        marginLeft: "auto"
    },
    fileUpload: {
        margin: 15
    }
})

interface StoreProps {
    posts: Post[]
    modalPost: Post | boolean
    user?: User
}

interface DispatchProps {
    methods: {
        posts: ArrayMethods<Post>
        notice: ObjectMethods<Notice>
        user: ObjectMethods<User>
    },
    handleClose: () => void
}

const mapStateToProps = (state: RootState): StoreProps => ({
    posts: state.posts.data,
    modalPost: state.postModal.data,
    user: state.user.data
})

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    const methods = mapDispatch(dispatch as any)
    return ({
        methods: {
            posts: methods.posts as ArrayMethods<Post>,
            notice: methods.notice as ObjectMethods<Notice>,
            user: methods.user as ObjectMethods<User>
        },
        handleClose: () => methods.postModal.clear()
    })
}

export const EditPostModal = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditPostModalComponent))
