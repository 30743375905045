import * as React from "react"
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    children: React.ReactNode
}

export function EmptyContent(props: Props) {
    const classes = useStyles();
    const {children} = props

    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200,
        height: "100%",
        width: "100%",
        padding: 16,
        color: theme.palette.primary.light,
        fontWeight: "bold",
        // border: `3px dashed ${theme.palette.primary.light}`
    }
}))
