import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {mapValues} from "lodash"
import {StoreObjectActions} from "./objectSliceTypes";
import {getObjectMethods, StoreObjectMethods} from "./objectMethods";
import {Dispatch} from "redux";
import {ErrorHandler} from "../store";
import {DraftStoreItemState, StoreItemState, StoreReducers, storeStatus} from "../types";


export const createObjectSlice = <T, K extends string>(objectInit: T, name: K) => {
    type State = StoreItemState<T>
    type DraftState = DraftStoreItemState<T>
    const initialState = {
        data: objectInit,
        status: storeStatus.INIT,
        message: null
    } as State
    return createSlice({
        name,
        initialState,
        reducers: {
            init: () => initialState,
            requested: (state: DraftState) => {
                state.status = storeStatus.REQUESTED
                state.message = null
            },
            loaded: (state: DraftState, action: PayloadAction<Draft<T>>) => {
                state.data = action.payload
                state.status = storeStatus.SUCCESS
                state.message = null
            },
            failed: (state: DraftState, action: PayloadAction<string>) => {
                state.status = storeStatus.FAILURE
                state.message = action.payload
            }
        }
    })
}

export const getStoreObjectMethods = <S extends object, E extends Error>(storeObjectInit: S) => {
    const objectSlices = mapValues(storeObjectInit, createObjectSlice)
    const objectActions = mapValues(objectSlices, (slice: any) => slice.actions) as StoreObjectActions<S>
    const objectReducers = mapValues(objectSlices, (slice: any) => slice.reducer) as StoreReducers<S>
    const objectMethods = (dispatch: Dispatch, errorHandler: ErrorHandler<E>) => {
        return mapValues(objectActions, getObjectMethods(dispatch, errorHandler)) as any as StoreObjectMethods<S>
    }
    return {objectActions, objectReducers, objectMethods}
}