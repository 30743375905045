import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {Post} from "../../app/models/Post";
import {CardActionArea} from "@material-ui/core";
import {Link} from "react-router-dom";
import Dotdotdot from 'react-dotdotdot'
import classNames from 'classnames';
import {PostHeader} from "./PostHeader";
import pattern from "../../assets/img/background_pattern_opaque.svg";
import cover from "../../assets/img/cover_white.svg";
import {RegaloSet} from "../regalo/RegaloSet";

interface Props {
    post: Post
    hideFooter?: boolean
}

export default function PostCard(props: Props) {
    const classes = useStyles();
    const {post, hideFooter} = props
    const blur = !post.hasPurchased && !post.ownPost
    return (
        <Card className={classes.root}>
            <PostHeader post={post} clamp/>
            <Link to={`/posts/${post.id}`}>
                <CardActionArea>
                        <CardMedia
                            className={blur ? classNames(classes.blur, classes.media) : classes.media}
                            image={post.thumbnail}
                            title={post.title}
                        />
                        {blur &&
                        <div className={classes.mediaOverlay}/>
                        }
                        <CardContent>
                            <div className={classes.description}>
                                <Dotdotdot clamp={3}>
                                    {post.description}
                                </Dotdotdot>
                            </div>
                        </CardContent>
                    {!hideFooter &&
                    <div className={classes.footer}>
                        {post.ownPost ?
                            <RegaloSet receive={post.value} size="small"/> :
                            (post.value === 0 || post.hasPurchased ?
                                <RegaloSet send={post.value}
                                           size="small"/> :
                                <RegaloSet user={post.creator.userToMemberRegalo}
                                           divideUser
                                           send={post.value}
                                           size="small"/>)
                        }
                    </div>
                    }
                </CardActionArea>
            </Link>
        </Card>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        textAlign: "left",
        background: "white",
        backgroundImage: `url(${pattern})`,
        backgroundSize: "100px 100px",
    },
    media: {
        position: "relative",
        height: 0,
        width: "100%",
        paddingTop: '56.25%', // 16:9
    },
    blur: {
        filter: "blur(4px)",
    },
    mediaOverlay: {
        position: "absolute",
        top: 0,
        height: 0,
        width: "100%",
        paddingTop: '56.25%', // 16:9
        backgroundImage: `url(${cover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    description: {
        height: 50,
        marginBottom: -10
    },
    footer: {
        textAlign: "center",
        padding: 12,
        display: "flex",
        justifyContent: "center",
        background: "#eee"
    }
}))
