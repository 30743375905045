import * as React from "react"
import {connect} from "react-redux"
import {
    CardMedia,
    TextField,
    InputAdornment,
    Button,
    withStyles,
    createStyles,
    WithStyles,
    Theme,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from "@material-ui/core"
import EmailIcon from "@material-ui/icons/Email"
import LockIcon from "@material-ui/icons/Lock"
import logo from '../../../assets/img/amithyst_logo.png'
import {RouteComponentProps, withRouter} from "react-router"
import {RootState, mapDispatch} from "../../../app/store";
import {Loading} from "../../../assets/img/Loading";
import {StoreItemState} from "../../../redux/types";
import {User} from "../../../app/models/User";
import {Dispatch} from "react";
import {ObjectMethods} from "../../../redux/object/objectMethods";
import {forgotPassword, login, register} from "../../../app/requests/auth";
import {Notice, noticeTypeEnum} from "../../../app/models/utils/types";

interface StoreProps {
    userState: StoreItemState<User>
    authModalOpen: boolean
}

interface DispatchProps {
    methods: {
        user: ObjectMethods<User>
        notice: ObjectMethods<Notice>
        authModalOpen: ObjectMethods<boolean>
    }
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}>, StoreProps, DispatchProps {
}

interface AuthModalState {
    username: string
    email: string
    password: string
    submitted: boolean
    signingUp: boolean
    forgotPassword: boolean
}

const initialState: AuthModalState = {
    username: "",
    email: "",
    password: "",
    submitted: false,
    signingUp: false,
    forgotPassword: false
}

class AuthModalComponent extends React.Component<Props, AuthModalState> {

    constructor(props: Props) {
        super(props)
        this.state = initialState
    }

    public render() {
        const {classes, authModalOpen} = this.props
        const {signingUp, forgotPassword} = this.state
        const validationMsg = this.getValidationMsg()
        const actionButtons = this.getActionButtons()

        return (
            <Dialog disableAutoFocus scroll="body"
                    open={authModalOpen} onClose={this.handleClose} className={classes.dialog}>
                <div className={classes.dialogBox}>
                    <DialogTitle className={classes.header}>
                        <CardMedia
                            className={classes.avatar}
                            image={logo} title="Amithyst Login"/>
                    </DialogTitle>
                    <DialogContent>
                        {validationMsg}
                        {!forgotPassword && <TextField
                            name="username" label="Username"
                            fullWidth required
                            value={this.state.username} onChange={this.handleChange}/>}

                        {(signingUp || forgotPassword) &&
                        <TextField
                            name="email" label="Email Address" fullWidth required
                            value={this.state.email} onChange={this.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailIcon color="primary"/>
                                    </InputAdornment>
                                )
                            }}
                        />}

                        {!forgotPassword &&
                        <TextField
                            name="password" label="Password" type="password" fullWidth required
                            value={this.state.password} onChange={this.handleChange} onKeyDown={this.handleKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LockIcon color="primary"/>
                                    </InputAdornment>
                                )
                            }}
                        />}

                        {!signingUp &&
                        <Button size="small" color="primary" className={classes.smallButton}
                                onClick={this.toggleForgotPassword}>{forgotPassword ? "Login / Sign up?" : "Forgot password?"}</Button>
                        }
                    </DialogContent>
                    <DialogActions>{actionButtons}</DialogActions>
                </div>
            </Dialog>
        )
    }

    private readonly getActionButtons = () => {
        const {userState, classes} = this.props
        const {signingUp, forgotPassword} = this.state
        const requested = userState.status === "REQUESTED"
        const loginButton =
            signingUp ? <Button onClick={this.toggleMode} className={classes.button}>Login</Button> :
                <Button variant="contained" color="primary" className={classes.button}
                        onClick={this.handleLogin} disabled={requested}>Login</Button>
        const signUpButton =
            signingUp ? <Button variant="contained" color="primary" className={classes.button}
                                onClick={this.handleSignUp} disabled={requested}>Sign Up</Button> :
                <Button onClick={this.toggleMode} className={classes.button}>Sign Up</Button>
        const signUpOrLogin = <>{loginButton}{requested && <Loading/>}{signUpButton}</>
        const passwordResetButton =
            <Button variant="contained" color="primary" className={classes.button}
                    onClick={this.handlePasswordReset} disabled={requested}>
                Send password reset link
            </Button>
        return forgotPassword ? passwordResetButton : signUpOrLogin
    }

    private readonly getValidationMsg = () => {
        const {classes} = this.props
        const {username, email, password, submitted, signingUp, forgotPassword} = this.state
        let validationMsg = ""
        if (submitted) {
            if (!forgotPassword && !username) validationMsg = "Username is required"
            else if (!password && !forgotPassword) validationMsg = "Password is required"
            else if ((signingUp || forgotPassword) && !email) validationMsg = "Email address is required"
        }
        return validationMsg ? <p className={classes.error}>{validationMsg}</p> : <></>
    }

    private readonly handleClose = () => {
        this.setState(initialState)
        this.props.methods.authModalOpen._load(false)
    }

    private readonly toggleMode = () => {
        const signingUp = !this.state.signingUp
        this.setState({signingUp})
    }

    private readonly toggleForgotPassword = () => {
        const forgotPassword = !this.state.forgotPassword
        this.setState({forgotPassword})
    }

    private readonly handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as keyof AuthModalState
        this.setState({
            [name]: event.target.value
        } as any)
    }

    private readonly handleLogin = () => {
        this.setState({submitted: true})
        const {username, password} = this.state
        if (username && password) {
            this.props.methods.user.load(login(username, password))
                .then((response) => {
                    this.props.methods.notice._load(({type: noticeTypeEnum.SUCCESS, message: "Login successful"}))
                })
                .then(this.handleClose)
        }
    }

    private readonly handleSignUp = () => {
        this.setState({submitted: true})
        const {email, password, username} = this.state
        if (email && password && username) {
            this.props.methods.user.load(register(
                username, email, password
            ))
                .then((response) => {
                    this.props.methods.notice._load(({
                        type: noticeTypeEnum.INFO,
                        message: `An email was sent to ${email}. Please verify your account.`
                    }))
                })
                .then(() => {
                    this.handleClose()
                    this.props.history.push(`/@${username}`)
                })
        }
    }

    private readonly handlePasswordReset = () => {
        this.setState({submitted: true})
        const {email} = this.state
        if (email) {
            forgotPassword(email)
                .then((response) => {
                    this.props.methods.notice._load(({type: noticeTypeEnum.SUCCESS, message: response.detail}))
                }).then(this.handleClose)
        }
    }

    private readonly handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            if (this.state.signingUp) {
                this.handleSignUp()
            } else {
                this.handleLogin()
            }
        }
    }
}

const styles = (theme: Theme) => createStyles({
    dialog: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 400,
    },
    dialogBox: {
        textAlign: "center"
    },
    cardMedia: {
        width: 100,
        height: 100
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 40
    },
    avatar: {
        width: 90,
        height: 90,
        margin: "30px auto -50px",
        boxShadow: "0 2px 5px #999",
        borderRadius: "50%",
        color: "white",
        backgroundColor: "#fff9ff"
    },
    button: {
        flex: 1,
        margin: 10
    },
    smallButton: {
        margin: "16px 0 -4px"
    },
    error: {
        fontSize: 12,
        color: theme.palette.error.main
    }
})

const mapStateToProps = (state: RootState): StoreProps => ({
    userState: state.user as StoreItemState<User>,
    authModalOpen: state.authModalOpen.data
})

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    const methods = mapDispatch(dispatch as any)
    return ({
        methods: {
            user: methods.user as ObjectMethods<User>,
            notice: methods.notice as ObjectMethods<Notice>,
            authModalOpen: methods.authModalOpen as ObjectMethods<boolean>
        }
    })
}

export const AuthModal = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AuthModalComponent)))
