import { FileModel } from "../models/File"
import {callEndpoint} from "./utils/requests";

export const uploadFile = (file: File) => {
    const formData = new FormData()
    formData.append("image", file, file.name)
    return callEndpoint<FileModel>("/api/thumbnails/", "POST", {
        data: formData,
        headers: {}
    })
}
