import {Avatar, IconButton, ListItemIcon, ListItemText, MenuItem} from "@material-ui/core";
import * as React from "react";
// @ts-ignore
import Flag from "react-flags"
import {useState} from "react";
import {Dropdown} from "../tools/Dropdown";
import LanguageIcon from "@material-ui/icons/Language"
import { useInvoke } from "../../app/store";

export const storeLang = (lang: Lang) => {
    localStorage.setItem('lang', lang)
}

export const getLang = (): Lang => {
    const lang = localStorage.getItem('lang')
    return lang ? lang as Lang : "EN"
}

type CountryCode = "GB" | "JP"
export type Lang = "EN" | "JP"

interface Country {
    code: CountryCode
    lang: Lang
    language: string
}

export const countries: Country[] = [{
    code: "GB",
    lang: "EN",
    language: "English"
}, {
    code: "JP",
    lang: "JP",
    language: "Japanese"
}]

export type Translations = {
    [L in Lang]: React.ReactNode
}

export function Language() {
    const [toggle, setToggle] = useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const invoke = useInvoke()
    const setLang = (lang: Lang) => () => {
        invoke.lang._load(lang)
        storeLang(lang)
    }
    return (
        <>
            <IconButton
                style={{
                    color: "white",
                }}
                aria-label="language"
                aria-haspopup="true"
                onClick={() => setToggle(!toggle)}
                ref={anchorRef}
            >
                <LanguageIcon/>
            </IconButton>
            <Dropdown toggle={toggle} setToggle={setToggle} anchorRef={anchorRef}
                      menuItems={countries.map((country) => (
                          <MenuItem onClick={setLang(country.lang)}>
                              <ListItemIcon>
                                  <Flag
                                      name={country.code}
                                      format="png"
                                      pngSize={32}
                                      basePath="/img/flags"
                                      alt={country.language}
                                  />
                              </ListItemIcon>
                              <ListItemText primary={country.language}/>
                          </MenuItem>
                      ))}/>
        </>
    )
}