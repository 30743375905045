import { MuiThemeProvider } from "@material-ui/core"
import { createMuiTheme } from "@material-ui/core/styles"
import * as React from "react"
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme"

export const appTheme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            dark: "#550055",
            main: "#cc11ee",
            light: "#dd44ee",
        },
        secondary: {
            light: "#fff9ff",
            main: "#ffccff",
            dark: "#ff99ff"
        }
    },
    // Make top bar appear above side bar
    zIndex: {
        appBar: 1200,
        drawer: 1100
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            // "-apple-system",
            // "BlinkMacSystemFont",
            "Segoe",
            "\"Segoe UI\"",
            "Calibri",
            'Noto Sans JP',
            "Arial",
            "Roboto",
            "\"Helvetica Neue\"",
            "Arial",
            "sans-serif",
            "\"Apple Color Emoji\"",
            "\"Segoe UI Emoji\"",
            "\"Segoe UI Symbol\""
        ].join(","),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 720,
            lg: 960,
            xl: 1200
        }
    }
} as ThemeOptions)

export class Theme extends React.Component {
    public render() {
        return (
            <MuiThemeProvider theme={appTheme}>
                {this.props.children}
            </MuiThemeProvider>
        )
    }
}
