import {ObjectId} from "../models/utils/types";
import {callEndpoint} from "./utils/requests";
import {Member} from "../models/Member";

export const getMemberByUsername = (username: string) =>
    callEndpoint<Member>(`/api/members/${username}/`, "GET", {})

export const getContacts = () =>
    callEndpoint<Member>(`/api/contacts/`, "GET", {})

export const getMembersByUsernames = (usernames: string[]) =>
    callEndpoint<Member[]>(`/api/members/?usernames=${usernames}`, "GET", {})
