import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Regalo, Size} from "./Regalo";

interface Props {
    user?: number,
    member?: number
    send?: number
    receive?: number
    size?: Size
    divideUser?: boolean
    divideMember?: boolean
}

export const RegaloSet = (props: Props) => {
    const classes = useStyles();
    const {user, member, send, receive, size, divideMember, divideUser} = props
    return (
        <div className={classes.root}>
            {user !== undefined && <Regalo variant="mine" value={user} size={size}/>}
            {divideUser && <span className={classes.slash}>/</span>}
            {send !== undefined && <Regalo variant="send" value={send} size={size}/>}
            {member !== undefined && <Regalo variant="theirs" value={member} size={size}/>}
            {divideMember && <span className={classes.slash}>/</span>}
            {receive !== undefined && <Regalo variant="receive" value={receive} size={size}/>}
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    slash: {
        fontSize: 20,
        color: theme.palette.primary.dark
    }
}))
