export interface Token {
    key: string
}

export const storeToken = (token: Token) => {
    localStorage.setItem('token', token.key)
}

export const getToken = () =>
    localStorage.getItem('token')

export const deleteToken = () =>
    localStorage.removeItem('token')

export const getCookie = (name: string) => {
  const cookie = {} as any;
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}