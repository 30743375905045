export type ObjectId = number

export interface Model {
    id: ObjectId
}

export type NoticeType = "ERROR" | "SUCCESS" | "INFO"
export type NoticeTypeEnum = {
    [K in NoticeType]: NoticeType
}

export const noticeTypeEnum: NoticeTypeEnum = {
    SUCCESS: "SUCCESS",
    INFO: "INFO",
    ERROR: "ERROR"
}

export interface Notice {
    type: NoticeType
    message: string
}
