import React, {Dispatch} from 'react';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {mapDispatch, RootState} from "../app/store";
import {RouteComponentProps} from 'react-router-dom';
import {NotificationList, NotificationListItem} from "../components/messages/NotificationList";
import {StoreItemState} from "../redux/types";
import {User} from "../app/models/User";
import {ObjectMethods} from "../redux/object/objectMethods";
import {ArrayMethods} from "../redux/array/arrayMethods";
import {needsRefresh} from "../app/requests/auth";
import {PaginatedResponse} from "../app/requests/utils/requests";
import {Group} from "../app/models/Group";
import {getGroups} from "../app/requests/groups";
import {StateLoader} from "../components/tools/StateLoader";
import {Button} from "@material-ui/core";

interface RouterParams {
    groupId: string
}

interface StoreProps {
    userState: StoreItemState<User>
    groupState: StoreItemState<Group[]>
}

interface DispatchProps {
    methods: {
        user: ObjectMethods<User>
        groups: ArrayMethods<Group>
    }
}

interface Props extends StoreProps, DispatchProps, WithStyles<typeof styles>, RouteComponentProps<RouterParams> {
}

interface State {
    page: number
    showMore: boolean
}

class MessagePageComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            page: 1,
            showMore: false
        }
    }

    public render() {

        const {classes, match, groupState} = this.props
        const groupId = parseInt(match.params.groupId)
        const {showMore} = this.state
        const items: NotificationListItem[] = groupState.data.map((group) => ({
            id: group.id,
            user: group.members[0],
            primary: group.name,
            secondary: group.lastMessage ? group.lastMessage.body : "No messages yet"
        }))
        return (
            <div className={classes.root}>
                <div className={classes.contactlist}>
                    <StateLoader
                        state={groupState}
                        emptySuccessMessage="No messages to show."
                        failureMessage="Failed to load messages."
                    >
                        <NotificationList items={items} selectedId={groupId}/>
                        {showMore &&
                            <Button color="primary" onClick={this.handleShowMore}>
                                Show More
                            </Button>
                        }
                    </StateLoader>
                </div>
            </div>
        )
    }

    public componentDidMount() {
        this.loadGroups()
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (
            needsRefresh(this.props.userState, prevProps.userState)
        ) {
            this.loadGroups()
        }
    }

    private readonly loadGroups = (reset: boolean = true) => {
        let {page} = this.state
        const {methods} = this.props
        page = reset ? 1 : page + 1
        this.setState({page})
        methods.groups.add(getGroups(page).then((res: PaginatedResponse<Group>) => {
            this.setState({showMore: !!res.next})
            return res.results || []
        }))
    }

    private readonly handleShowMore = () => {
        this.loadGroups(false)
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        display: "flex"
    },
    contactlist: {
        width: 360,
        [theme.breakpoints.down('sm')]: {
            width: 240,
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
        background: "white"
    },
})

const mapStateToProps = (state: RootState): StoreProps => ({
    userState: state.user as StoreItemState<User>,
    groupState: state.groups as StoreItemState<Group[]>,
})

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    const methods = mapDispatch(dispatch as any)
    return ({
        methods: {
            user: methods.user as ObjectMethods<User>,
            groups: methods.groups as ArrayMethods<Group>,
        }
    })
}

export const MessagePage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessagePageComponent))

