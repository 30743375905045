import * as React from "react"
import {
    Snackbar,
    SnackbarContent,
    IconButton
} from "@material-ui/core"
import {green} from "@material-ui/core/colors"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../../app/store";
import {useEffect} from "react";

export function NoticeSnackbar() {
    const classes = useStyles();
    const {notice} = useSelector(
        (state: RootState) => ({
            notice: state.notice.data
        }))
    const invoke = useInvoke()
    const handleClose = (event?: any, reason?: any) => {
        if (reason !== "clickaway") invoke.notice.clear()
    }
    useEffect(() => {
        if (notice) {
            setTimeout(invoke.notice.clear, 5000)
        }
    }, [notice])

    const open = notice !== undefined
    let icon = <></>
    if (notice) {
        const Icon = variantIcon[notice.type]
        icon = <Icon/>
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            open={open}
            onClose={handleClose}
        >
            {notice &&
            <SnackbarContent
                className={classes[notice.type]}
                message={
                    <span className={classes.message}>
                            {icon}
                        <span className={classes.text}>
                            {notice.message}
                            </span>
                        </span>
                }
                action={[
                    <IconButton
                        key="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon className={classes.icon}/>
                    </IconButton>
                ]}
            />
            }
        </Snackbar>
    )
}

const variantIcon = {
    SUCCESS: CheckCircleIcon,
    ERROR: ErrorIcon,
    INFO: InfoIcon
}

const useStyles = makeStyles((theme) => ({
    SUCCESS: {
        backgroundColor: green[600]
    },
    ERROR: {
        backgroundColor: theme.palette.error.dark
    },
    INFO: {
        backgroundColor: theme.palette.info.dark
    },
    message: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    text: {
        margin: 8
    }
}))
