import React, {useState} from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Post} from "../../app/models/Post";
import {UserAvatar} from "../user/UserAvatar";
import {formatDate} from "../../utils/datetime";
import {Link} from "react-router-dom";
import Dotdotdot from "react-dotdotdot";
import {Dropdown} from "../tools/Dropdown";
import {ListItemIcon, ListItemText, MenuItem, DialogContentText} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../../app/store";
import EditIcon from '@material-ui/icons/Edit';
import {deletePost} from "../../app/requests/posts";
import DeleteIcon from '@material-ui/icons/Delete';
import {ModalDialog} from "../tools/ModalDialog";

interface Props {
    post: Post
    clamp?: boolean
}

export function PostHeader(props: Props) {
    const {post, clamp} = props
    const [toggle, setToggle] = useState(false)
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    const invoke = useInvoke()
    const openEditModal = () => invoke.postModal._load(post)
    const isMyPost = user && (user.username === post.creator.username)
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const userAvatar =
        <Link to={`/@${post.creator.username}`}>
            <UserAvatar user={post.creator}/>
        </Link>
    const handleDeletePost = () => {
        const postId = post.id
        deletePost(postId).then(() => {
            invoke.posts._removeOne(postId)
        })
    }
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const subheader = post.creator.username + " - " + formatDate(post.createdAt)
    return (
        <>
            <ModalDialog
                message={
                    <DialogContentText>
                        Are you sure you want to permanently delete your post?
                    </DialogContentText>}
                actions={["Delete"]}
                handleActions={[handleDeletePost]}
                open={open}
                handleClose={handleClose}
            />
            <CardHeader
                avatar={userAvatar}
                action={isMyPost && <>
                    <IconButton
                        aria-label="account"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => setToggle(!toggle)}
                        ref={anchorRef}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Dropdown toggle={toggle} setToggle={setToggle} anchorRef={anchorRef}
                              menuItems={[
                                  <MenuItem onClick={openEditModal}>
                                      <ListItemIcon>
                                          <EditIcon/>
                                      </ListItemIcon>
                                      <ListItemText primary="Edit post"/>
                                  </MenuItem>,
                                  <MenuItem onClick={handleOpen}>
                                      <ListItemIcon>
                                          <DeleteIcon/>
                                      </ListItemIcon>
                                      <ListItemText primary="Delete post"/>
                                  </MenuItem>
                              ]}/>
                </>}
                title={clamp ?
                    <Dotdotdot clamp={1}>
                        {post.title}
                    </Dotdotdot> : post.title}
                subheader={clamp ?
                    <Dotdotdot clamp={1}>
                        {subheader}
                    </Dotdotdot> : subheader}
            />
        </>
    );
}
