import { ObjectId, Model } from "./utils/types"
import {Member} from "./Member";

export type ContentType = "IMAGE" | "AUDIO" | "VIDEO" | "PDF"
type ContentTypeEnum = {
    [K in ContentType]: ContentType
}

export const contentTypeEnum: ContentTypeEnum = {
    IMAGE: "IMAGE",
    AUDIO: "AUDIO",
    VIDEO: "VIDEO",
    PDF: "PDF",
}

export interface PostState {
    id?: ObjectId
    thumbnail?: string
    title: string
    description: string
    category?: ObjectId | null
    contentType: ContentType
    file?: File
    value: number
}

export interface Post extends Model, Omit<Omit<PostState, "id">, "file">  {
    creator: Member
    createdAt: string
    updatedAt: string
    hasAccess: boolean
    hasPurchased: boolean
    ownPost: boolean
    file: string
}