import {Dispatch} from "redux";
import {ObjectActions} from "./objectSliceTypes";
import {ErrorHandler} from "../store";


export interface ObjectMethods<T> {
    load: (dataPromise: Promise<T>) => Promise<T>
    _load: (data: T) => Promise<T>
    clear: () => void
}

export type StoreObjectMethods<S> = {
    [K in keyof S]: ObjectMethods<S[K]>
}

export const getObjectMethods = <E extends Error>(dispatch: Dispatch, errorHandler: ErrorHandler<E>) =>
    <T>(action: ObjectActions<T>): ObjectMethods<T> => {
    const loadAction = (dataPromise: Promise<T>) => {
        dispatch(action.requested())
        return dataPromise
            .then((data) => {
                dispatch(action.loaded(data))
                return data
            })
            .catch((error: E) => {
                const errorAction = errorHandler(error)
                if (errorAction) dispatch(errorAction)
                dispatch(action.failed(error.message))
                return Promise.reject(error.message)
            })
    }
    return {
        load: loadAction,
        _load: (data: T) => loadAction(Promise.resolve(data)),
        clear: () => dispatch(action.init()),
    }
}