import moment from "moment"

export const compareDates = (a: string | null, b: string | null) => {
    if (!b) return 1
    if (!a) return -1
    const date_a = moment(a)
    const date_b = moment(b)
    return date_a.diff(date_b)
}

interface WithUpdate {
    updatedAt: string
}

export const sortByUpdatedDate = (a: WithUpdate, b: WithUpdate) => -compareDates(a.updatedAt, b.updatedAt)
