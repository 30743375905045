import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";
// @ts-ignore
import useResizeAware from 'react-resize-aware';

interface Item {
    key: string | number
    data: any
}

interface Props<T> {
    items: Item[]
    renderer: (x: T) => React.ReactNode
    tileWidth: number
    tileMargin: number
}

export function TiledView<T>(props: Props<T>) {
    const {items, renderer, tileWidth, tileMargin} = props
    const tileTotalWidth = tileWidth + 2 * tileMargin
    const extraMargin = 12
    const [resizeListener, sizes] = useResizeAware();
    const width = sizes.width
    const classes = useStyles(tileWidth, tileMargin)
    const paddingLeft = (!width || width <= tileTotalWidth * 2 + extraMargin) ? 5 :
        ((width - extraMargin) % tileTotalWidth + extraMargin) / 2
    return (
        <div className={classes.root} style={{paddingLeft}}>
            {resizeListener}
            {items.map((item: Item) =>
                <div className={classes.tile} key={item.key}>
                    {renderer(item.data)}
                </div>
            )}
            <br style={{clear: "both"}}/>
        </div>
    )
}


const useStyles = (tileWidth: number, tileMargin: number) => makeStyles((theme) => ({
    root: {
        padding: tileMargin,
    },
    tile: {
        margin: tileMargin,
        width: tileWidth,
        [theme.breakpoints.down('sm')]: {
            width: `calc(50% - 10px)`
        },
        [theme.breakpoints.down('xs')]: {
            width: `calc(100% - 10px)`
        },
        float: "left"
    }
}))()