import {Avatar} from "@material-ui/core"
import * as React from "react"
import {AvatarProps} from "@material-ui/core/Avatar/Avatar"
import {User} from "../../app/models/User";
import {Member} from "../../app/models/Member";

interface Props extends AvatarProps {
    user?: User | Member
}

export function UserAvatar(props: Props) {
    const {user} = props
    return (
        <Avatar
            {...props}
            style={{
                color: "white",
                textAlign: "center",
                ...(user ? {backgroundColor: userToColour(user)} : {})
            }}
            src={user && user.thumbnail}
        >
            {(user && !user.thumbnail) ? <span className="fas fa-user"/> : <></>}
        </Avatar>
    )
}

const userToColour = (user: User | Member): string => {
    /** HSL colour can take values from 0 - 360 (0 and 360 are both red). Increasing the value by 31 (which is
     * relatively prime to 360) makes sure that users with similar ids will have decently different avatar colours,
     * and that the avatar colour won't loop until the user ids differ by more than 360 within a cohort.
     */

    return `hsl(${user.id * 31 % 360}, 100%, 30%)`
}
