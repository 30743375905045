import * as React from "react"
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import {Link} from "react-router-dom"
import {UserAvatar} from "../UserAvatar";
import {useSelector} from "react-redux";
import {RootState, useInvoke} from "../../../app/store";
import {useState} from "react";
import {logout} from "../../../app/requests/auth";
import {Dropdown} from "../../tools/Dropdown";


export function Auth() {
    const {user} = useSelector((state: RootState) => ({
        user: state.user.data
    }))
    const invoke = useInvoke()
    const openAuth = () => invoke.authModalOpen._load(true)
    const [toggle, setToggle] = useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    return (
        <>
            {user ?
                <>
                    <IconButton
                        aria-label="account"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => setToggle(!toggle)}
                        ref={anchorRef}
                        style={{padding: 0, margin: "0 12px 0"}}
                    >
                        <UserAvatar user={user}/>
                    </IconButton>
                    <Dropdown toggle={toggle} setToggle={setToggle} anchorRef={anchorRef}
                              menuItems={[
                                  <Link to={`/@${user.username}`}>
                                      <MenuItem>
                                          <ListItemIcon>
                                              <span className="fas fa-address-card"/>
                                          </ListItemIcon>
                                          <ListItemText primary="Profile"/>
                                      </MenuItem>
                                  </Link>,
                                  /*<Link to="/account/">
                                      <MenuItem>
                                          <ListItemIcon>
                                              <span className="fas fa-user"/>
                                          </ListItemIcon>
                                          <ListItemText primary="My account"/>
                                      </MenuItem>
                                  </Link>*/
                                  <Link to="/" onClick={logout}>
                                      <MenuItem>
                                          <ListItemIcon>
                                              <span className="fas fa-sign-out-alt"/>
                                          </ListItemIcon>
                                          <ListItemText primary="Logout"/>
                                      </MenuItem>
                                  </Link>
                              ]}/>
                </>
                :
                <IconButton color="inherit" aria-label="Login" onClick={openAuth}>
                    <AccountCircle/>
                </IconButton>
            }
        </>
    )
}