import * as React from "react"
import {CSSProperties} from "react"
// @ts-ignore
import useResizeAware from 'react-resize-aware';

interface Props {
    maxWidth: number
    style?: CSSProperties
    styleDown?: CSSProperties
    styleUp?: CSSProperties
    className?: string
    children: React.ReactNode
}

export function Container(props: Props) {
    const {children, maxWidth, style, styleUp, styleDown, className} = props
    const [resizeListener, sizes] = useResizeAware();
    const rootWidth = sizes.width
    const width = rootWidth && (rootWidth < maxWidth) ? rootWidth : maxWidth
    const extraStyle = rootWidth ?
        ((rootWidth >= maxWidth) ?
            (styleUp ? styleUp : {})
            : (styleDown ? styleDown : {}))
        : {}
    return (
        <div style={{width: "100%", height: "100%"}}>
            {resizeListener}
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width,
                    ...style,
                    ...extraStyle
                }}
                className={className}
            >
                {children}
            </div>
        </div>
    )
}
